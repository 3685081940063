@import "../../../../govuk-frontend/dist/govuk/settings/all";
@import "../../../../govuk-frontend/dist/govuk/tools/all";
@import "../../../../govuk-frontend/dist/govuk/helpers/all";
@import "../../../../govuk-frontend/dist/govuk/vendor/sass-mq";

.hmrc-timeout-overlay {
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background-color: govuk-colour("black");
  filter: alpha(opacity=80);
  -moz-opacity: 0.8;
}

html {
  &.no-scroll {
    overflow: hidden;
  }
}

.hmrc-timeout-dialog {
  position: fixed;
  z-index: 1002;
  top: 50%;
  left: 50%;
  width: 280px;
  padding: 30px;
  overflow-x: hidden;
  overflow-y: auto;
  transform: translate(-50%, -50%);
  border: 5px solid govuk-colour("black");
  background-color: govuk-colour("white");

  @include govuk-media-query($from: tablet) {
    width: 435px;
  }
}

.hmrc-timeout-dialog__message {
  @include govuk-font($size: 19);

  line-height: 1.31579;
}

.hmrc-timeout-dialog__countdown {
  display: inline-block;
  font-weight: 700;
  white-space: nowrap;
}

.hmrc-timeout-dialog__link-wrapper {
  @include govuk-media-query($from: tablet) {
    display: inline-block;
    line-height: 2.5em;
    vertical-align: baseline;
  }
}

.hmrc-timeout-dialog__link {
  @include govuk-font($size: 24);

  display: inline-block;

  @include govuk-media-query($from: tablet) {
    @include govuk-font($size: 19);

    position: relative;
    top: 2px;
    padding: 0.526315em 0.789473em 0.263157em;
    line-height: 1.5;
  }
}
