$govuk-header-link-underline-thickness: 3px;

.hmrc-header.hmrc-header--with-additional-navigation {
  .govuk-header__service-name {
    float: left;
  }

  .hmrc-sign-out-nav {
    float: right;
  }

  .hmrc-sign-out-nav__link {
    color: govuk-colour("white");
    font-weight: bold;
    line-height: 1.8em;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &:focus {
      color: #0b0c0c;
    }
  }

  .hmrc-language-select {
    padding-right: govuk-spacing(3);
    float: right;

    .govuk-list {
      margin-bottom: 0;

      a:not(.govuk-header__link) {
        @include govuk-font($size: 16, $weight: "bold");

        color: govuk-colour("mid-grey");
        text-decoration: none;
      }
    }

    .govuk-header__navigation-item {
      display: inline-block;
      margin-right: 0;
      border-bottom: 0;
    }

    .govuk-header__navigation-item + .govuk-header__navigation-item::before {
      content: "|";
      padding-right: 2px;
      color: govuk-colour("dark-grey");
    }
  }
}

.hmrc-header__service-name {
  display: inline-block;
  margin-bottom: govuk-spacing(2);

  @include govuk-font-size($size: 24);
  @include govuk-typography-weight-bold;
}

.hmrc-header__service-name--linked {
  @include govuk-link-style-inverse;

  text-decoration: none;

  &:hover {
    text-decoration: underline;
    text-decoration-thickness: $govuk-header-link-underline-thickness;

    @if $govuk-link-underline-offset {
      text-underline-offset: $govuk-link-underline-offset;
    }
  }

  &:focus {
    @include govuk-focused-text;
  }
}

// Shift user research banner up 10px to compensate for govuk header border
// only when adjacent to govuk header
.hmrc-header + .hmrc-user-research-banner {
  top: -10px;
}
