.hmrc-notification-badge {
  @include govuk-font($size: 14);

  display: inline-block;
  min-width: 10px;
  padding: 1px 4px 0 4px;
  border-radius: 10px;
  color: govuk-colour("white");
  background: govuk-colour("red");
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
}
